import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import { formatter } from '@/utils/helpers/formatter.js';
import budgetViewAndSelect from '@/pages/budget-planner-v2/create-plan/steps/previewStep/tableCells/budgetViewAndSelect.vue';
import budgetWithPercentage from '@/pages/budget-planner-v2/create-plan/steps/previewStep/tableCells/budgetWithPercentage.vue';

const budgetViewAndSelectDef = () => {
  return {
    field: 'name',
    headerComponentFramework: 'genericTableHeader',
    headerName: `Scope`,
    title: `Scope`,
    cellRendererFramework: 'DynamicCellComponent',
    pinned: true,
    headerComponentParams: {
      enableSorting: false,
      toolTipText: 'Totals',
      keyType: 'string'
    },
    cellStyle: (params) => {
      const level = params?.data?.['ag-grid-level'];
      if (level === 1) {
        return { 'padding-left': '28px' };
      } else if (level === 2) {
        return { 'padding-left': '36px' };
      }
      return null;
    },
    suppressSizeToFit: false,
    minWidth: 250,
    cellRendererParams: {
      component: budgetViewAndSelect,
      eventMap: {
        rowSelected: 'rowSelected'
      },
      paramsToProps: (params) => {
        return {
          params
        };
      }
    },
    eventMap: {}
  };
};

const budgetWithPercentageDef = () => {
  return {
    field: 'total',
    headerComponentFramework: 'genericTableHeader',
    headerName: `Budget Plan ${new Date().getFullYear()}`,
    title: `Budget Plan ${new Date().getFullYear()}`,
    cellRendererFramework: 'DynamicCellComponent',
    pinned: false,
    headerComponentParams: {
      enableSorting: false,
      toolTipText: 'Totals',
      keyType: 'string'
    },
    suppressSizeToFit: false,
    minWidth: 250,
    cellRendererParams: {
      component: budgetWithPercentage,
      paramsToProps: (params) => {
        return {
          params
        };
      }
    },
    eventMap: {}
  };
};

export const masterTableLevelConfig = [
  {
    level: 1,
    getter: 'budgetPlannerOmni/getMasterTableLevel1',
    action: 'budgetPlannerOmni/createMasterTableLevel1'
  },
  {
    level: 2,
    getter: 'budgetPlannerOmni/getMasterTableLevel2',
    action: 'budgetPlannerOmni/createMasterTableLevel2'
  }
];

export const slaveTableLevelConfig = [
  {
    level: 1,
    getter: 'budgetPlannerOmni/getSlaveTableLevel1',
    action: 'budgetPlannerOmni/createSlaveTableLevel1'
  }
];

const columnDefGenerator = (field, name, tooltip, pinned, type = 'string') => {
  let formatterFn = null;
  if (type === 'currency') {
    formatterFn = formatter;
  }
  return {
    field,
    headerComponentFramework: 'genericTableHeader',
    cellRendererParams: {
      keyType: type,
      formatterFn
    },
    cellStyle: (params) => {
      const level = params?.data?.['ag-grid-level'];
      if (level > 0) {
        return { 'padding-left': '28px' };
      }
      return null;
    },
    cellRenderer: cellRenderer,
    headerName: name,
    pinned: pinned,
    title: name,
    headerComponentParams: {
      enableSorting: false,
      toolTipText: tooltip,
      keyType: type
    },
    keyOrder: 4,
    suppressSizeToFit: false,
    minWidth: 150
  };
};

export const masterTableColDef = [
  budgetViewAndSelectDef(),
  budgetWithPercentageDef()
];

export const slaveTableColDef = [
  columnDefGenerator(
    'name',
    'Scope',
    'Selected distribution levels',
    true,
    'string'
  ),
  budgetWithPercentageDef()
];
