<template>
  <div class="u-display-flex u-width-100 bpv2-preivew">
    <div
      class="u-width-50 fillContainerHeightMaster u-position-relative u-display-flex u-flex-direction-column u-spacing-pt-xxl"
    >
      <budgetPlannerHeader :step="masterHeaderTitle" />
      <div
        class="u-flex-1 u-height-100 u-overflow-auto bp-preview-table-container"
      >
        <rb-insights-table
          :config="{}"
          :grid-options="masterTableGridOptions"
          :table-column="masterTableColumns"
          :table-row="getMasterTableData"
          :row-height="45"
          class="u-spacing-pr-xl"
          :enable-client-side-sorting="false"
          :has-expand="true"
          :expand-cell-width="65"
          :levels="masterTableLevels"
          table-id="budget-preview-master"
          :has-default-row-expand="true"
        />
      </div>

      <budgetPlannerFooter
        :disable-right-btn="inputInvalid"
        :show-left-btn="true"
        btn-right-text="Next"
        class="u-spacing-pt-xl"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="previousStep"
      />
      <div
        v-if="inputInvalid"
        class="u-spacing-pt-s u-font-size-6 u-color-red-base"
      >
        Please input the budget plan to proceed to the next step.
      </div>
    </div>
    <div
      class="u-width-50 slave-table-background u-flex-direction-column u-position-relative u-spacing-p-xxl u-overflow-y-auto"
    >
      <div
        class="u-flex-direction-column u-display-flex u-spacing-pl-xxs u-spacing-pb-s u-color-grey-mid-light"
      >
        <budgetPlannerHeader :step="slaveHeader" />
        <div
          class="u-font-weight-600 u-display-flex u-font-size-5 u-color-grey-base"
        >
          <div class="u-text-case-title">{{ levelName }}</div>
          <div
            v-if="levelName && selectedEntity"
            class="u-spacing-ph-xs"
          >
            >
          </div>
          <div
            v-if="selectedEntity"
            class="u-text-case-title"
          >
            {{ selectedEntity }}
          </div>
        </div>
      </div>
      <loader
        class="fill--parent slave-table-background"
        :loading="loadSlaveTable"
        color="#3684bb"
      />
      <div
        class="u-flex-1 fillContainerHeightSlave bp-preview-table-container u-overflow-auto"
      >
        <rb-insights-table
          :config="{}"
          :grid-options="slaveTableGridOptions"
          :table-column="slaveTableColumns"
          :table-row="getSlaveTableData"
          :row-height="45"
          :enable-client-side-sorting="false"
          :has-expand="true"
          :expand-cell-width="65"
          :levels="slaveTableLevels"
          table-id="budget-preview-master"
          :has-default-row-expand="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {
  masterTableColDef,
  masterTableLevelConfig,
  slaveTableColDef,
  slaveTableLevelConfig
} from '@/pages/budget-planner-v2/create-plan/steps/previewStep/preivewConfig.js';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer.vue';
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header.vue';
import loader from '@/components/basic/loader';
import utils from '@/utils/helpers';

export default {
  components: {
    loader,
    budgetPlannerHeader,
    budgetPlannerFooter
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      levelName: 'Entire Business',
      slaveHeader: {
        header: {
          title: `Monthly and Quarterly distribution`,
          desc: [
            'Here is the Monthly and Quarterly distribution of the selection mode'
          ]
        }
      },
      selectedEntity: null,
      loadSlaveTable: false,
      masterTableColumns: [],
      delimiter: '|-|',
      masterTableLevels: [],
      slaveTableColumns: [],
      slaveTableLevels: [],
      masterTableGridOptions: {
        context: {
          componentParent: this
        }
      },
      slaveTableGridOptions: {
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    automationsEnabled() {
      const internalUserCheck = utils.internalUserCheck(window.user);
      const automationsConfigCheck = Vue.options.filters.config_check(
        'feature.pages.boV2_automations'
      )?.enable;
      return internalUserCheck && automationsConfigCheck;
    },
    masterHeaderTitle() {
      const selectedLevels =
        this.$store.getters['budgetPlannerOmni/getSelectedLevels'];
      let headerTitle = '';
      for (const level in selectedLevels) {
        if (headerTitle !== '') {
          headerTitle += '& ';
        }
        if (selectedLevels[level]?.dimensionLabel) {
          headerTitle += `${selectedLevels[level]?.dimensionLabel} `;
        }
      }
      headerTitle += 'distribution';
      return {
        header: {
          title: headerTitle,
          desc: ['The budget plan you have provided is as follows']
        }
      };
    },
    getSlaveTableData() {
      return this.$store.getters?.['budgetPlannerOmni/getSlaveTableData'] || [];
    },
    getMasterTableData() {
      return (
        this.$store.getters?.['budgetPlannerOmni/getMasterTableData'] || []
      );
    },
    inputInvalid() {
      const entireBusinessTotal = this.getMasterTableData?.[0]?.total;
      return entireBusinessTotal === null;
    }
  },
  created() {
    this.$store.dispatch('budgetPlannerOmni/createMasterTableEntireBusinsess');
    this.masterTableColumns = masterTableColDef;
    this.masterTableLevels = masterTableLevelConfig;
    this.slaveTableColumns = slaveTableColDef;
    this.slaveTableLevels = slaveTableLevelConfig;
  },
  methods: {
    callPacingApi() {
      const isNextYearPlan = this.$route.query?.nextYearPlan;
      this.$store.dispatch('budgetPlannerOmni/formPacingPayload', {
        isNextYearPlan: !!isNextYearPlan,
        isAutomationsEnabled: this.automationsEnabled
      });
    },
    nextStep() {
      !this.automationsEnabled && this.callPacingApi();
      this.$emit('nextStep', {});
    },
    previousStep() {
      !this.automationsEnabled && this.callPacingApi();
      this.$emit('prevStep', {});
    },
    rowSelected(row) {
      this.loadSlaveTable = true;
      setTimeout(() => {
        this.loadSlaveTable = false;
      }, 400);
      const { originalRow } = row.params;
      const { ui_label } = originalRow;
      this.levelName = originalRow.levelName;
      this.selectedEntity = originalRow.name
        .split(this.delimiter)
        .map((item) => (item === 'null' ? ui_label : item))
        .reverse()
        .join(' > ');
      this.$store.dispatch('budgetPlannerOmni/createSlaveTable', originalRow);
    }
  }
};
</script>
