<template>
  <div
    class="u-width-100 u-color-grey-base u-display-flex u-text-align-left u-flex-align-items-center"
  >
    <div
      v-if="params.data.total !== null"
      class="u-display-flex u-width-100 u-text-align-left u-flex-align-items-center"
    >
      <div>
        {{ params.data.total | num_format('currency') }}
      </div>
      <div
        v-if="params.data.percentage >= 0"
        class="u-display-flex percentageText u-font-size-7 u-color-grey-lighter u-spacing-ml-s"
      >
        {{ params.data.percentage | num_format(undefined, '%') }}
        <div class="perc-scope u-spacing-pl-xs u-color-grey-base">
          of
          {{ params.data.parent }}
        </div>
      </div>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: function () {
        return {};
      }
    }
  }
};
</script>
